<template>
  <v-container
    id="regular-tables-view"
    fluid
    tag="section"
  >

    <material-card
      icon="mdi-clipboard-text"
      icon-small
      title="Всі замовлення"
      color="accent"
    >
      <v-row class="mx-2 mb-2">
        <v-col cols="12" md="3">
          <v-select
            return-object
            item-text="name"
            item-value="name"
            v-model="project"
            :items="projects"
            label="Проект"
            clearable
          ></v-select>
        </v-col>

        <v-col cols="12" md="3">
          <v-select
            return-object
            item-text="name"
            item-value="name"
            v-model="type"
            :items="types"
            label="Тип замовлення"
            clearable
          ></v-select>
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            return-object
            item-text="name"
            item-value="name"
            v-model="statusVal"
            :items="status"
            label="Статус замовлення"
            clearable
          ></v-select>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="t22"
            label="Документ Тайфун"
            clearable
          ></v-text-field>
        </v-col>
      </v-row>
      
      <v-simple-table class="order-table">
        <thead>
          <tr>
            <th class="primary--text" width="10%">
              ID та дата
            </th>
            <th class="primary--text" width="4%">
              Проект
            </th>
            <th class="primary--text" width="4%">
              Тип
            </th>
            <th class="primary--text" width="8%">
              Статус
            </th>
            <th class="primary--text" width="8%">
              Т22
            </th>
            <th class="primary--text" width="15%">
              Доставка
            </th>
            <th class="primary--text" width="15%">
              Місто
            </th>
            <th class="primary--text" width="16%">
              Покупець
            </th>
            <th class="primary--text" width="15%">
              Коментар
            </th>
            <th v-if="isAdmin" width="5%"></th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(order, index) in orders" :key="`order-${index}`" @dblclick="toUserProfil(order.id)">
            <td>
              <span class="font-weight-medium">{{ order.id || '-' }}</span> <br> 
              <span class="caption">{{ order.date | formatDate }}</span>
            </td>
            <td class="one-line">{{ order.project_name || '-' }}</td>
            <td>{{ types.find(el => el.id === order.type) ? types.find(el => el.id === order.type).name : '-' }}</td>
            <td>
              <v-chip class="d-block text-center white--text font-weight-bold" small :color="`#${order.status_color}`">
                {{ order.status_name || '-' }}
              </v-chip>
              <span class="table-marker" :style="`background-color: #${order.status_color}`"></span>
            </td>
            <td>{{ order.t22 || '-' }}</td>
            <td>{{ order.delivery_naem || '-' }}</td>
            <td class="one-line">{{ order.city_name || '-' }}</td>
            <td><span class="font-weight-medium">{{ order.full_name || '-' }}</span> <br/> {{ order.phone || '-' }}  </td>
            <td>{{ order.comment || '-' }}</td>
            <td v-if="isAdmin">
              <span class="d-flex">
                <v-icon
                  class="mr-4"
                  color="green"
                  @click="toUserProfil(order.id)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  color="error"
                  @click="onDialog(order.id)"
                >
                  mdi-delete
                </v-icon>
              </span>
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <div v-if="orders.length && totalCount > pageSize" class="text-center py-4">
        <v-pagination
          v-model="currentPage"
          :total-visible="9"
          :length="pageLength"
        ></v-pagination>
      </div>
    </material-card>

    <v-dialog
      v-model="dialog"
      max-width="400">
      <v-card>
        <v-card-title class="text-h5 align-center">
          Ви дійсно хочете видалити?
        </v-card-title>
        <v-card-actions class="flex justify-center">
          <v-btn
            color="primary" 
            min-width="150"
            text
            @click="onRemoveOrder"
          >
            Так
          </v-btn>
          <v-btn
            color="grey"
            min-width="150"
            text
            @click="closeDialog"
          >
            Нi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { HTTP } from '../http-common';
import { mapGetters, mapMutations } from 'vuex'

  export default {
    name: 'RegularTablesView',
    data() {
      return {
        enabled: null,
        orders: [],
        project: {},
        type: {},
        statusVal: {},
        t22: null,
        currentPage: 1,
        pageSize: 10,
        totalCount: null,
        dialog: false,
        orderId: false,
        projects: []
      }
    },
    computed: {
      ...mapGetters(['isAdmin', 'types', 'status']),
      pageLength() {
        if (this.totalCount) {
          return Math.round(this.totalCount / this.pageSize)
        }
      }
    },
    methods: {
      ...mapMutations(['setSnackbar', 'getStatus']),
      getOrders() {
        HTTP.post('/get-orders', {
          project_id: this.project && this.project.id || null,
          type_id: this.type && this.type.id || null,
          status_id: this.statusVal && this.statusVal.id || null,
          doc_t22: this.t22 ? this.t22 : null,
          limit: this.pageSize,
          page: this.currentPage
        })
          .then(({ data }) => {
            if (data.content) {
              this.orders = data.content.data
              this.totalCount = data.content.total_count || null
            }
          })
      },
      getProjects() {
        HTTP.post('/get-projects', {
          user_use: true
        })
          .then(response => {
            if (response.data && response.data.content) {
              this.projects = response.data.content
            }
          })
          .catch(error => {
              console.log(error);
          })
      },
      onRemoveOrder() {
        this.dialog = false
        if (this.isAdmin) {
          HTTP.post('/delete-order', {
            'id': this.orderId
          })
            .then(() => {
              this.setSnackbar({ type: 'success', text: 'Успiшно видалено' });
              this.getOrders();
            })
            .catch((error) => {
              this.setSnackbar({ type: 'error', text: error.message });
            })
          }
      },
      toUserProfil(id) {
        this.$router.push(`/orders/${id}`);
      },
      scrollToTop() {
        window.scrollTo({
          top: 120,
          behavior: 'smooth'
        })
      },
      onDialog(id) {
        this.dialog = true
        this.orderId = id
      },
      closeDialog() {
        this.dialog = false 
        this.orderId = null
      },
      setFilters() {
        if(this.$route.query.page) {
          this.currentPage = Number(this.$route.query.page);
        }
        if(this.$route.query.project) {
          this.project = this.projects.find(el => el.id == this.$route.query.project)
        }
        if(this.$route.query.type) {
          this.type = this.types.find(el => el.id == this.$route.query.type)
        }
        if(this.$route.query.status) {
          this.statusVal = this.status.find(el => el.id == this.$route.query.status)
        }
      },
      pushQuery() {
        let data = {};

        if (this.currentPage > 1) {
          data.page = this.currentPage;
        }
        if (this.project && this.project.id) {
          data.project = this.project.id;
        }
        if (this.type && this.type.id) {
          data.type = this.type.id;
        }
        if (this.statusVal && this.statusVal.id) {
          data.status = this.statusVal.id;
        }

        this.$router.push({
          query: data
        })
      }
    },
    watch: {
      project() {
        this.currentPage = 1;
        this.pushQuery();
        this.getOrders();
      },
      type() {
        this.currentPage = 1;
        this.pushQuery();
        this.getOrders();
      },
      statusVal() {
        this.currentPage = 1;
        this.pushQuery();
        this.getOrders();
      },
      t22() {
        this.currentPage = 1;
        this.getOrders();
      },
      currentPage() {
        this.pushQuery();
        this.getOrders();
        this.scrollToTop();
      }
    },
    mounted() {
      this.setFilters()
      this.getProjects();
      this.getStatus();
      this.getOrders();
    }
  }

</script>
